/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    strong: "strong",
    ul: "ul",
    li: "li",
    em: "em"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "In this product film for ", React.createElement(_components.strong, null, "TechnoTeam Bildverarbeitung GmbH"), " aims to present the hightlights of the LMK position."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "It is intended to be an entertaining film that appeals equally to the target group of display manufacturers, i.e. engineers and other decision-makers, such as CEOs."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Special features: We fade in the highlights of the device while you see the robot doing its daily work: Taking measurements on displays."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.em, null, "Fun Fact: The special camera sits on a robot arm that is very special: it is the only one that is painted matt black. Other industrial robots are usually white. A bright colour could interfere with the measurement due to reflection.")), "\n"), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
